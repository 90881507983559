import './MenuTopMobile.css'
import menu from '../../icons/menu.png';
import { getElementError } from '@testing-library/react';
function MenuTopMobile() {
  function OpenMenu(){
    document.getElementById("MenuMobile").style.display="block";
  }
  return (
    <div className="MenuTopMobile_Components">
      <div className="logo">PLS</div>
      <div className="BtnMenuMobile" onClick={OpenMenu}>
        <img src={menu} alt="Menu" className="MenuImg" />
      </div>
    </div>
  );
}
  
export default MenuTopMobile;
  