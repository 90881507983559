import React, {useState } from 'react';
import './Supportcourse.css'
import supportcourse from '../design/supportcourse.jpg'
import Reussir from '../design/Reussir.jpg'
import examen from '../design/examen.jpg'
import university from '../design/university.png'
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';


function Supportcourse() {
    const [Autre, setAutre] = useState('none');
    const handelInpAutre = (event)=>{
        if(event.target.value==="Autre"){
            setAutre("block");
        }else{
            setAutre("none");
        }
    }
    const scrollToForm = () => {
        scroller.scrollTo('FormInscription', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
    };
    

    return (
      <div className="Supportcourse_Page">
        <div className="slider">
            <img src={supportcourse} alt="supportcourse" className='supportcourseImg' />
            <div className="filterSilder"></div>
            <div className="TitleAndBtnSlider">
                <div className="Title">Inscrivez-vous dès maintenant pour les cours de soutien 2024 - 2025 </div>
                <Link className='BtnInscrir' onClick={scrollToForm}>Inscrire</Link>
            </div>
        </div>
        <div className="FormPlace">
            <div className="FormPlaceTitle" id='FormInscription'>Formulaire :</div>
            <div className="ZoneInputs">
                <div className="ZoneInputsPlace">
                    <div className="ZoneInputsPlacePart inpLeft">
                        <div className="ZoneInputsPlacePartTitle">Nom complet</div>
                        <div className="ZoneInputsPlacePartInput">
                            <input type="text" className='inp' placeholder='Nom complet' />
                        </div>
                    </div>
                    <div className="ZoneInputsPlacePart inpRight">
                        <div className="ZoneInputsPlacePartTitle">Niveau académique</div>
                        <div className="ZoneInputsPlacePartInput">
                        <select className='inp'>
                            <option value="">--- Niveau académique ---</option>
                            <option value="Première 1">Première 1</option>
                            <option value="Première 2">Première 2</option>
                            <option value="Première 3">Première 3</option>
                            <option value="Première 4">Première 4</option>
                            <option value="Première 5">Première 5</option>
                            <option value="Première 6">Première 6</option>
                            <option value="Collège 1">Collège 1</option>
                            <option value="Collège 2">Collège 2</option>
                            <option value="Collège 3">Collège 3</option>
                            <option value="Lycée 1">Lycée 1</option>
                            <option value="Lycée 2">Lycée 2</option>
                            <option value="Lycée 3">Lycée 3</option>
                            <option value="Bac +1">Bac +1</option>
                            <option value="Bac +2">Bac +2</option>
                            <option value="Bac +3">Bac +3</option>
                            <option value="Bac +4">Bac +4</option>
                            <option value="Bac +5">Bac +5</option>
                            <option value="Bac plus que 5">Bac plus que 5</option>
                        </select>
                        </div>
                    </div>
                </div>
                <div className="ZoneInputsPlace">
                    <div className="ZoneInputsPlacePart inpLeft">
                        <div className="ZoneInputsPlacePartTitle">Téléphone</div>
                        <div className="ZoneInputsPlacePartInput">
                            <input type="text" className='inp' placeholder='Téléphone' />
                        </div>
                    </div>
                    <div className="ZoneInputsPlacePart inpRight">
                        <div className="ZoneInputsPlacePartTitle">Ville</div>
                        <div className="ZoneInputsPlacePartInput">
                            <input type="text" className='inp' placeholder='Ville' />
                        </div>
                    </div>
                </div>
                <div className="ZoneInputsPlace">
                    <div className="ZoneInputsPlacePart inpLeft">
                        <div className="ZoneInputsPlacePartTitle">Matière</div>
                        <div className="ZoneInputsPlacePartInput">
                            <select className='inp' onChange={handelInpAutre}>
                                <option value="">--- Matière ---</option>
                                <option value="Math">Mathématiques</option>
                                <option value="PC">Physique et chimique (PC)</option>
                                <option value="SVT">Sciences de la vie et la terre (SVT)</option>
                                <option value="Autre">Autre </option>
                            </select>
                        </div>
                    </div>
                    <div className="ZoneInputsPlacePart inpRight" style={{display:Autre}}>
                        <div className="ZoneInputsPlacePartTitle">Autre</div>
                        <div className="ZoneInputsPlacePartInput">
                            <input type="text" className='inp' placeholder='Autre ' />
                        </div>
                    </div>
                </div>
                <div className="BtnInscrirForm">Inscrire</div>
            </div>
            <div className="FormPlaceTitle">Objectifs atteints :</div>
            <div className="paneils">
                <div className="paneil">
                    <img src={Reussir} alt="Reussir" className='Reussir' />
                    <div className="degradationPaneil"></div>
                    <div className="ProgressPlace">
                        <div className="progress-bar">
                            <progress value="75" min="0" max="100" style={{visibility: 'hidden', height: 0, width: 0}}>75%</progress>
                        </div>
                    </div>
                    <div className="TitlePaneil">Succès en 2023/2024</div>
                </div>
                <div className="paneil">
                    <img src={examen} alt="Reussir" className='Reussir' />
                    <div className="degradationPaneil"></div>
                    <div className="ProgressPlace">
                        <div className="progress-bar2">
                            <progress value="75" min="0" max="100" style={{visibility: 'hidden', height: 0, width: 0}}>75%</progress>
                        </div>
                    </div>
                    <div className="TitlePaneil">Bonnes notes</div>
                </div>
                <div className="paneil">
                    <img src={university} alt="Reussir" className='Reussir' />
                    <div className="degradationPaneil"></div>
                    <div className="ProgressPlace">
                        <div className="progress-bar3">
                            <progress value="75" min="0" max="100" style={{visibility: 'hidden', height: 0, width: 0}}>75%</progress>
                        </div>
                    </div>
                    <div className="TitlePaneil">Sélectionné par les écoles</div>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Supportcourse;
  