import './Home.css'
import devlopment_slider from '../design/devlopment_slider.jpg'
import fleche from '../icons/fleche.png'
import IA from '../design/IA.jpeg'
import dev from '../design/dev.jpg'
import study from '../design/study.jpg'
import technologie from '../icons/technologie.png'
import secteur from '../icons/secteur.png'
import educatif from '../icons/educatif.png'

function Home() {
    return (
      <div className="Home_Page">
        <div className="slider">
          <div className="description">
            <div className="Title">Consultez et gérez vos problèmes informatiques.</div>
            <div className="descriptionText">Abonnez-vous pour bénéficier d'un service innovant pour organiser et développer vos projets informatiques rapidement avec nos meilleurs ingénieurs.</div>
            <div className="linkDecouver">
              Découvrez maintenant
              <img src={fleche} alt="fleche" className="fleche" />
            </div>
            <div className="squere2">
              <div className="squere_center"></div>
            </div>
          </div>
          <div className="design">
            <img src={devlopment_slider} alt="Design" className="desingImage" />
            <div className="squere">
              <div className="squere_center"></div>
            </div>
          </div>
        </div>
        <div className="secteurs">
          <div className="secteursItem">
            <div className="DesignSecteurs">            
              <img src={IA} alt="IA" className='IA' />
              <div className="Degradation"></div>
              <div className="HoverDesign">
                <div className="HoverDesignCenter">
                  <div className="HoverDesignLeft"></div>
                </div>
              </div>
            </div>
            <div className="TitleSecteurs">Intelligence artificielle</div>
            <div className="TextSecteurs">POLESCIENCE a fait des avancées majeures en IA pour améliorer et accélérer la communication entre les clients et les machines. Nous intégrons l'IA pour rendre les interactions plus fluides et efficaces. Avec POLESCIENCE, l'avenir de la communication homme-machine devient une réalité.</div>
            <img src={fleche} alt="fleche" className="flecheSecteurs" />
          </div>
          <div className="secteursItem">
            <div className="DesignSecteurs">            
              <img src={dev} alt="IA" className='IA' />
              <div className="Degradation"></div>
              <div className="HoverDesign">
                <div className="HoverDesignCenter">
                  <div className="HoverDesignLeft"></div>
                </div>
              </div>
            </div>
            <div className="TitleSecteurs">Développement d'Applications Innovantes</div>
            <div className="TextSecteurs">POLESCIENCE se spécialise dans le développement d'applications innovantes, créées pour répondre aux besoins spécifiques de nos clients. Nous concevons des solutions sur mesure qui allient performance, convivialité et technologie de pointe, assurant ainsi une expérience utilisateur optimale et un impact durable sur votre activité.</div>
            <img src={fleche} alt="fleche" className="flecheSecteurs" />
          </div>
          <div className="secteursItem">
            <div className="DesignSecteurs">            
              <img src={study} alt="IA" className='IA' />
              <div className="Degradation"></div>
              <div className="HoverDesign">
                <div className="HoverDesignCenter">
                  <div className="HoverDesignLeft"></div>
                </div>
              </div>
            </div>
            <div className="TitleSecteurs">Plateforme Éducative</div>
            <div className="TextSecteurs">POLESCIENCE offre une plateforme éducative avancée, conçue pour faciliter l'obtention de certificats et le suivi de cours de soutien. Nos programmes en ligne sont interactifs et adaptés aux besoins des apprenants, offrant des ressources de qualité et un accompagnement personnalisé pour garantir le succès académique et professionnel.</div>
            <img src={fleche} alt="fleche" className="flecheSecteurs" />
          </div>
        </div>
        <div className="zone">
          <div className="zoneItem">
            <div className="zoneItemCenter">
              <div className="imgTitreZone">
                <img src={technologie} alt="technologie" className="imgtechnologie" />
                <div className="TitleZone">Technologies</div>
              </div>
              <div className="listItems">
                <ul>
                  <li className="itemZone">API technologie</li>
                  <li className="itemZone">Configuration de serveur</li>
                  <li className="itemZone">Configuration réseau</li>
                  <li className="itemZone">Création d'une application</li>
                  <li className="itemZone">Implémentation d'IA</li>
                  <li className="itemZone">Scraping de données</li>
                  <li className="itemZone">Résolution de problèmes...</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="zoneItem">
            <div className="zoneItemCenter">
              <div className="imgTitreZone">
                <img src={secteur} alt="technologie" className="imgtechnologie" />
                <div className="TitleZone">Secteurs</div>
              </div>
              <div className="listItems">
                <ul>
                  <li className="itemZone">Secteur bancaire et financier</li>
                  <li className="itemZone">Santé</li>
                  <li className="itemZone">Commerce électronique</li>
                  <li className="itemZone">Éducation</li>
                  <li className="itemZone">Télécommunications</li>
                  <li className="itemZone">Transport et logistique</li>
                  <li className="itemZone">Médias et divertissement...</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="zoneItem withoutborder">
            <div className="zoneItemCenter">
              <div className="imgTitreZone">
                <img src={educatif} alt="educatif" className="imgtechnologie" />
                <div className="TitleZone">Pédagogique</div>
              </div>
              <div className="listItems">
                <ul>
                  <li className="itemZone">Préparation au 1er Baccalauréat</li>
                  <li className="itemZone">Préparation au 2ème Baccalauréat</li>
                  <li className="itemZone">Préparation aux concours des écoles</li>
                  <li className="itemZone">Études à l'étranger</li>
                  <li className="itemZone">Formation certifiée</li>
                  <li className="itemZone">Examen National</li>
                  <li className="itemZone">Examen Régional...</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="lineDegrader"></div>
      </div>
    );
  }
  
  export default Home;
  