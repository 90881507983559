import React, { useRef,useState } from 'react';
import './Register.css'
import { Link } from 'react-router-dom';
import register from '../design/register.jpg';
import axios from 'axios';

function Register() {
    const dateInputRef = useRef(null);
  
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Level, setLevel] = useState('');
    const [Birthday, setBirthday] = useState('');
    const [Password, setPassword] = useState('');


    const handlePhoneInput = (event) => {
      const { value } = event.target;

      const onlyNumbers = value.replace(/[^0-9]/g, ''); 
      if (onlyNumbers.length <= 10) { 
        setPhone(onlyNumbers);
      }
    };

    const handleContainerClick = () => {
      dateInputRef.current.showPicker();  // Triggers the date picker
    };

    const Register = async () => {
      try {
        const response = await axios.post('https://auth.funibia.com/api/users/register/add', {
          firstname:FirstName,
          lastname:LastName,
          email:Email,
          phone:Phone,
          level:Level,
          birthday:Birthday,
          password:Password,
        });
  
        if (response.status === 201) {
          SendCodeActivation(Email);
        } else {
          alert('L\'inscription a échoué vérifiez vos informations');
        }
      } catch (error) {
        alert('Quelque chose ne va pas');
      }
    };

    const checkInputsIfEmpty=()=>{
      if(FirstName==="" ||LastName==="" ||Email==="" ||Phone==="" ||Level==="" ||Birthday==="" ||Password===""){
        alert("Remplissez tous les champs");
      }else{
        if(!isValidEmail(Email)){
          alert("Email non valide");
        }else{
          if(!isValidPhoneNumber(Phone)){
            alert("Téléphone non valide");
          }else{
            if(!isValidPassword(Password)){
              alert("Utilisez 8 caractères dans le mot de passe");
            }else{
              Register();
            }
          }
        }
      }
    }

    function isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    function isValidPhoneNumber(phoneNumber) {
      const phoneRegex = /^(06|07)\d{8}$/;
      return phoneRegex.test(phoneNumber);
    }
    function isValidPassword(password) {
      return password.length >= 8;
    }

    const SendCodeActivation=async(email)=>{
      try {
        const response = await axios.post('https://auth.funibia.com/api/users/code/send', {
          to:email,
        });
  
        if (response.status === 201) {
          window.location.href = "/validation/"+email;
        }else{
          alert('Quelque chose ne va pas');
        }
      } catch (error) {
        alert('Quelque chose ne va pas');
      }
    }



    return (
      <div className="Register_Page">
        <div className="Desing">
          <img src={register} alt="register" className='loginImg' />
        </div>
        <div className="Form">
          <div className="TitleLogin">S'INSCRIRE</div>
          <div className="TitleLogin2">Nous serions ravis de vous accueillir à PoleScience</div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Nom</div>
              <div className="InpZone">
                <input type="text" className='inp' value={LastName} placeholder='Alami,...' onChange={(event) => setLastName(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Prénom</div>
              <div className="InpZone">
                <input type="text" className='inp' value={FirstName} placeholder='Ali,...' onChange={(event) => setFirstName(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Email</div>
              <div className="InpZone">
                <input type="text" className='inp' value={Email} placeholder='abcd@gmail.com' onChange={(event) => setEmail(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Téléphone</div>
              <div className="InpZone">
                <input type="text" className='inp' placeholder='06 22 33 66 ...' value={Phone} onChange={handlePhoneInput}/>
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Niveau scolaire</div>
              <div className="InpZone">
                <select className='inp' value={Level} onChange={(event) => setLevel(event.target.value)}>
                  <option value="">---- Niveau scolaire ----</option>
                  <option value="P1">Primaire 1</option>
                  <option value="P2">Primaire 2</option>
                  <option value="P3">Primaire 3</option>
                  <option value="P4">Primaire 4</option>
                  <option value="P5">Primaire 5</option>
                  <option value="P6">Primaire 6</option>
                  <option value="C1">Collège 1</option>
                  <option value="C2">Collège 2</option>
                  <option value="C3">Collège 3</option>
                  <option value="L1">Tronc commun</option>
                  <option value="L2">Première baccalauréat</option>
                  <option value="L3">Deuxième baccalauréat</option>
                  <option value="B1">Bac +1</option>
                  <option value="B2">Bac +2</option>
                  <option value="B3">Bac +3</option>
                  <option value="B4">Bac +4</option>
                  <option value="B5">Bac +5</option>
                  <option value="B">Bac plus que 5</option>
                </select>
              </div>
            </div>
          </div>
          <div className="PlaceInp" onClick={handleContainerClick}>
            <div className="PlaceInpCentre">
              <div className="TitleInp">Anniversaire</div>
              <div className="InpZone">
                <input type="date" id="date" className='inp inpdate' value={Birthday} ref={dateInputRef} onChange={(event) => setBirthday(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Mot de passe</div>
              <div className="InpZone">
                <input type="password" className='inp' value={Password} placeholder='***********' onChange={(event) => setPassword(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="BtnConnexion" onClick={checkInputsIfEmpty}>S'inscrire</div>
            </div>
          </div>
          <div className="PlaceInp marginTopLink">
            <div className="PlaceInpCentre">
              <div className="LinkPlace">Vous avez un compte ?<Link className='WordLink' to="/login">Connexion</Link></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Register;
  