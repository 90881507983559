
function Nofound() {
    return (
      <div className="Nofound_Page">
        Nofound
      </div>
    );
  }
  
  export default Nofound;
  