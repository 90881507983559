// src/Layout.js
import Menu_Bottom from './Components/Menu_Bottom/Menu_Bottom'
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import MenuTopWeb from './Components/Menu_Top_Web/MenuTopWeb'; // Adjust the path as necessary
import MenuTopMobile from './Components/Menu_Top_Mobile/MenuTopMobile'; // Adjust the path as necessary
import Home from './Home/Home';
import Login from './Login/Login';
import Register from './Register/Register';
import Validation from './Validation/Validation';
import Supportcourse from './Supportcourse/Supportcourse';
import Nofound from './Nofound/Nofound';
import close from './icons/close.png';
import home from './icons/home.png';
import IT from './icons/IT.png';
import study from './icons/study.png';
import job from './icons/job.png';
import contact from './icons/contact.png';

const Layout = () => {
  function CloseMenu(){
    document.getElementById("MenuMobile").style.display="none";
  }
  return (
    <div className="Layout">
        <div className="Layout_center">
            <MenuTopWeb />
            <MenuTopMobile />

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/validation/*" element={<Validation />} />
                <Route path="/Supportcourse" element={<Supportcourse />} />
                <Route path="*" element={<Nofound />} />
            </Routes>
            <Menu_Bottom/>
            <div className="MenuList" id="MenuMobile">
                <div className="top">
                  <div className="title">Menu</div>
                  <div className="BtnClose" onClick={CloseMenu}>
                    <img src={close} alt="close" className="close" />
                  </div>
                </div>
                <div className="listMenu">
                  <img src={home} alt="home" className="home" />
                  <div className="TitleText">Accueil</div>
                </div>
                <div className="listMenu">
                  <img src={IT} alt="IT" className="home" />
                  <div className="TitleText">Technologie</div>
                </div>
                <div className="listMenu">
                  <img src={study} alt="study" className="home" />
                  <div className="TitleText">Éducation</div>
                </div>
                <div className="listMenu">
                  <img src={job} alt="job" className="home" />
                  <div className="TitleText">Emploi</div>
                </div>
                <div className="listMenu">
                  <img src={contact} alt="contact" className="home" />
                  <div className="TitleText">Contact</div>
                </div>
                <div className="PlaceBtnAccount">
                  <div className="BtnMyAccount">Mon compte</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Layout;
