import React, { useState, useEffect } from 'react';
import './Validation.css';
import validation from '../design/validation.webp';
import { Link } from 'react-router-dom';
import axios from 'axios';


function Validation() {
    const [seconds, setSeconds] = useState(60);
    const [secondsDisp, setSecondsDisp] = useState("(après 60s)");
    const [code, setCode] = useState("");

    useEffect(() => {
        document.getElementById('SendCode').style.cursor="no-drop";
        if (seconds > 0) {
            const intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            // Cleanup the interval when component is unmounted or seconds reach 0
            return () => clearInterval(intervalId);
        } else {
            setSecondsDisp(""); // Clear the display text when the countdown ends
            document.getElementById('SendCode').style.cursor="pointer";
        }
    }, [seconds]);

    useEffect(() => {
        if (seconds > 0) {
            setSecondsDisp(`(après ${seconds}s)`);
        }
    }, [seconds]);

    const changeCode= async ()=>{
        const parts = getCurrentUrl().split('/');
        const emailUrl = parts[parts.length - 1];
        if(isValidEmail(emailUrl)){
            try {
                const response = await axios.post('https://auth.funibia.com/api/users/code/send', {
                    to:emailUrl,
                });
          
                if (response.status === 201) {
                    alert('Code envoyé avec succès');
                } else {
                  alert('Operation failure');
                }
            } catch (error) {
                alert('Operation failure');
            }
        }else{
            alert("E-mail invalide")
        }
    }

    const activeAccount= async ()=>{
        const parts = getCurrentUrl().split('/');
        const emailUrl = parts[parts.length - 1];
        if(isValidEmail(emailUrl)){
            if(code.length===6){
                try {
                    const response = await axios.post('https://auth.funibia.com/api/users/code/validate', {
                        email:emailUrl,
                        codeUser:code,
                    });
              
                    if (response.status === 200) {
                        window.location.href = "/login";
                    } else if (response.status === 408){
                      alert('Générer un autre code');
                    } else if (response.status === 401){
                        alert('Compte déjà valide');
                    }else if (response.status === 406){
                        alert('Code incorrect');
                    }else {
                        alert('Code incorrect');
                    }
                } catch (error) {
                    alert('Code incorrect');
                }
            }else{
                alert("Code invalide")
            }
        }else{
            alert("E-mail invalide")
        }
    }

    function getCurrentUrl() {
        return window.location.href;
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    function startCountdown() {
        if(seconds===0){
            changeCode();
            document.getElementById('SendCode').style.cursor="no-drop";
            setSeconds(60); // Reset to 60 seconds
            setSecondsDisp("(après 60s)");
        }
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        const isValidInput = /^\d{0,6}$/.test(value);

        if (isValidInput) {
            setCode(value);
        }
    };

    return (
        <div className="Validation_Page">
            <div className="Desing">
                <img src={validation} alt="login" className="loginImg" />
            </div>
            <div className="Form">
                <div className="TitleLogin">Validation</div>
                <div className="TitleLogin2">
                    Nous serions ravis de vous accueillir à PoleScience
                </div>
                <div className="PlaceInp">
                    <div className="PlaceInpCentre">
                        <div className="TitleInp">Code</div>
                        <div className="InpZone">
                            <input type="text" className="inp" placeholder="*** ***" onChange={handleInputChange} value={code} />
                        </div>
                    </div>
                </div>
                <div className="PlaceInp">
                    <div className="PlaceInpCentre">
                        <div className="BtnConnexion" onClick={activeAccount}>Valider</div>
                    </div>
                </div>
                <div className="PlaceInp">
                    <div className="PlaceInpCentre">
                        <div className="BtnConnexion BtnEnvoiCode" id='SendCode' onClick={startCountdown}>
                            Envoyer le code {secondsDisp}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Validation;
