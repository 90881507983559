import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Library.css';
import { BrowserRouter as Router} from 'react-router-dom';
import Layout from './Layout';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Layout />
  </Router>
);
