import React, { useState } from 'react';
import './Login.css'
import login from '../design/login.jpg'
import { Link } from 'react-router-dom';
import axios from 'axios';


function Login() {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const loginUser = async()=>{
    try {
      const response = await axios.post('https://auth.funibia.com/api/users/login', {
          email:Email,
          password:Password,
      });
      if (response.status === 200) {
          alert("login sussefuly");
          console.log(response.data);
      }
    } catch (error) {
      const { status, data } = error.response;
      if (status === 401){
        if(data.error==="Account not valide"){
          SendCodeActivation(Email);
        }else if(data.error==="password or email inccorrect"){
          alert("Mot de passe ou email incorrect");
        }else if(data.error==="account already using"){
          alert("Le compte est actuellement utilisé");
        }else{
          alert("Opération rejetée");
        }
      }else{
        console.log("nothing");
      }
    }
  }

  const SendCodeActivation=async(email)=>{
    try {
      const response = await axios.post('https://auth.funibia.com/api/users/code/send', {
        to:email,
      });

      if (response.status === 201) {
        window.location.href = "/validation/"+email;
      }else{
        alert('Quelque chose ne va pas');
      }
    } catch (error) {
      alert('Quelque chose ne va pas');
    }
  }



    return (
      <div className="Login_Page">
        <div className="Desing">
          <img src={login} alt="login" className='loginImg' />
        </div>
        <div className="Form">
          <div className="TitleLogin">CONNEXION</div>
          <div className="TitleLogin2">Nous serions ravis de vous accueillir à PoleScience</div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Email</div>
              <div className="InpZone">
                <input type="text" className='inp' placeholder='abcd@gmail.com' value={Email} onChange={(event) => setEmail(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="TitleInp">Mot de passe</div>
              <div className="InpZone">
                <input type="password" className='inp' placeholder='**********' value={Password} onChange={(event) => setPassword(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="PlaceInp">
            <div className="PlaceInpCentre">
              <div className="BtnConnexion" onClick={loginUser}>Connexion</div>
            </div>
          </div>
          <div className="PlaceInp marginTopLink">
            <div className="PlaceInpCentre">
              <div className="LinkPlace">Vous n'avez pas un compte ?<Link className='WordLink' to="/register">S'inscrire</Link></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Login;
  