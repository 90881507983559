import './MenuTopWeb.css'
function MenuTopWeb() {
    return (
      <div className="MenuTopWeb_Components">
        <div className="logoAndMenu">
            <div className="logo">PLS</div>
            <div className="ListMenu">
                <div className="btnMenu">Accueil</div>
                <div className="btnMenu">Technologie</div>
                <div className="btnMenu">Éducation</div>
                <div className="btnMenu">Emploi</div>
                <div className="btnMenu">Contact</div>
            </div>
        </div>
        <div className="placeAccount">
            <div className="BtnAccount">
                <div className="leftBtnAccount"></div>
                <div className="rightBtnAccount"></div>
                <div className="TextBtnAccount">Mon compte</div>
            </div>
        </div>
      </div>
    );
  }
  
  export default MenuTopWeb;
  