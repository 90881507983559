import './Menu_Bottom.css'
import linkedin from '../../icons/linkedin.png'
import instagram from '../../icons/instagram.png'
import facebook from '../../icons/facebook.png'
function Menu_Bottom() {
  return (
    <div className="Menu_Bottom">
      <div className="InfoCompany">
        <div className="logo">PLS</div>
        <div className="slogon">Votre chemin vers le succès</div>
        <div className="descriptionCompany">Fondée en 2020, Polescience se positionne parmi les leaders mondiaux dans le domaine des services-conseils en technologie de l'information et en gestion. Nous sommes animés par une approchefondée sur les faits et axée sur la performance, visant à optimiser le rendement des investissements de nos clients.</div>
        <div className="copyright">© 2024 Polescience</div>
      </div>
      <div className="listMenuItem">
        <div className="listMenuPlace">
            <div className="titleMenuBottom">Qui sommes-nous</div>
            <div className="titleMenuBottomItem">Blogues</div>
            <div className="titleMenuBottomItem">Evénements</div>
            <div className="titleMenuBottomItem">Secteurs</div>
        </div>
        <div className="listMenuPlace">
            <div className="titleMenuBottom">Recrutement</div>
            <div className="titleMenuBottomItem">Inscription PFE</div>
            <div className="titleMenuBottomItem">Inscription PFA</div>
            <div className="titleMenuBottomItem">Inscription Travail</div>
        </div>
      </div>
      <div className="inscriptionPlace">
        <div className="titleFollow">Plus sur POLESCIENCE</div>
        <div className="inscriptionFollow">Inscrivez-vous</div>
        <div className="RSocail">Retrouvez-nous sur les réseaux</div>
        <div className="links">
            <div className="linksItem">
                <img src={linkedin} alt="linkedin" className="iconSocail" />
            </div>
            <div className="linksItem">
                <img src={instagram} alt="linkedin" className="iconSocail" />
            </div>
            <div className="linksItem">
                <img src={facebook} alt="linkedin" className="iconSocail" />
            </div>
        </div>
      </div>
    </div>
  );
}
  
export default Menu_Bottom;
  